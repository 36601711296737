import React from "react"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql } from "gatsby"

import "./datenschutz.scss"
import BlockContent from "../components/block-content"

const DatenschutzPage = ({ data: { sanityLegalSettings: data } }) => {
  return (
    <Layout page="datenschutz">
      <SEO title="Datenschutz" />
      <Row>
        <Col xs={12} className="content">
          <h2 className="mb-5">Datenschutzerklärung</h2>
          <BlockContent blocks={data._rawPrivacy} />
        </Col>
      </Row>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PrivacyLegalPageSettingsQuery {
    sanityLegalSettings(_id: { eq: "legalSettings" }) {
      _rawPrivacy
    }
  }
`

export default DatenschutzPage
